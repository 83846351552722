<template>
  <div>
    <div class="md-layout main-stats">
      <div class="md-layout-item md-size-20 md-small-size-100 mx-auto count-stat">
        <h3>Completed</h3>
        <div class="count-up">
          <ICountUp
            ref="completed"
            :endVal="results.completed"
            :options="countUpOptions"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-20 md-small-size-100 mx-auto count-stat">
        <h3>Excluded</h3>
        <div class="count-up">
          <ICountUp
            ref="excluded"
            :endVal="results.excluded"
            :options="countUpOptions"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-20 md-small-size-100 mx-auto count-stat">
        <h3>Incompleted</h3>
        <div class="count-up">
          <ICountUp
            ref="incompleted"
            :endVal="results.incompleted"
            :options="countUpOptions"
          />
        </div>
      </div>
    </div>
    <div v-if="results.targets" class="md-layout">
      <h2>Targets</h2>
      <div v-for="(target, key) in results.targets" :key="key" class="md-layout-item md-size-20 md-small-size-100 mx-auto count-stat">
        <h3>{{ key }}</h3>
        <div class="count-up">
          <ICountUp
            :endVal="target"
            :options="countUpOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ICountUp from 'vue-countup-v2'
  import swal from 'sweetalert2'

  export default {
    name: 'demo',
    components: {
      ICountUp
    },
    data() {
      return {
        countUpOptions: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: ''
        },
        results: {
          targets: null,
          completed: 0,
          excluded: 0,
          incompleted: 0
        }
      }
    },
    created () {
      this.loadResults()
    },
    methods: {
      loadResults () {
        var self = this
        var xhr = new XMLHttpRequest()
        xhr.open('GET', this.$root.apiUrl + 'v1/results/' + this.$route.params.id + '/' + this.$route.params.status + '/status')
        xhr.onload = function () {
          if (xhr.status === 401) self.$router.push({name: 'Login'})
          var response = JSON.parse(xhr.responseText)
          if (xhr.status === 200 && response.response) {
            self.results = response.response
          } else if (xhr.status === 404) {

          } else {
            var message
            if (response.response && response.response[0] && response.response[0].message)
              message = response.response[0].message
            else
              message = 'Unknown error'
            self.showSweetAlertError(message)
          }
        }
        xhr.send()
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'OK',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      }
    }
  };
</script>

<style scoped>
  .count-up {
    font-size: 5em;
    margin: .5em 0;
    color: #f00;
  }
  .main-stats {
    padding-top: 2em;
  }
  .count-stat {
    text-align: center;
  }
  h2 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
  }
</style>
